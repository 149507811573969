import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Copywriting Services",
  whatWeOffer: {
    leftPart: "Copywriting is a very broad term. It incorporates a ton of seemingly similar marketing-related concepts. However, each one is specific in its own way.",
    rightPart: "At Podroom Creative, you get the full package. We can take care of all your advertising material needs. With a decade of experience in the niche, we can help you write for pamphlets, leaflets, on-site content, guest blogging content, emails, ads, catalogs... You name it!"
  },
  processText: "For all your copywriting needs, contact us today.",
  testimonials: testimonials
}

const meta = {
  title: "Copywriting Services",
  description: "Copywriting is a very broad term. It incorporates a ton of seemingly similar marketing-related concepts. However, each one is specific in its own way."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/copywriting";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)